import { deleteCookie } from 'cookies-next';

const stage = process.env['NEXT_PUBLIC_STAGE'];

export const deleteCrossDomainCookie = (key: string) => {
  if (stage === 'local' || stage === 'local-dev') {
    deleteCookie(key);
  } else {
    deleteCookie(key, {
      domain: '.requityhomes.com',
      path: '/',
      secure: true,
      sameSite: 'lax',
    });
  }
};
